.preview-animation-enter {
  opacity: 0;
  width: 0;
  flex: 0;
}
.preview-animation-enter-active {
  opacity: 1;
  width: unset;
  flex: 4;
  //transition: all 300ms;

  animation: animOpacityIn 300ms ease-in 0,
    animResizeIn 300ms ease-in 300ms;
}
.preview-animation-exit {
  opacity: 1;
  flex: 4;
  background-color: red;
}
.preview-animation-exit-active {
  //opacity: 0;
  //flex: 0;
  //transition: all 300ms;

  animation: animOpacityOut 300ms ease-in 0,
    animResizeOut 300ms ease-in 1000ms;
}

@keyframes animOpacityIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes animOpacityOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes animResizeIn {
  from {
    width: 0;
    flex: 0;
  }
  to {
    width: unset;
    flex: 4;
  }
}

@keyframes animResizeOut {
  from {
    width: unset;
    flex: 4;
  }
  to {
    width: 0;
    flex: 0;
  }
}
