h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.recharts-responsive-container {
  flex: 1;
}

.recharts-default-tooltip {
  border: solid 1px #cccccc !important;
  border-radius: 8px;
  font-size: 13px;
  padding: 8px 16px !important;
  color: #4834d4 !important;
}

.show-mobile, .show-mobile-block {
  display: none !important;
}

@media only screen and (max-width: 900px) {
  .hide-mobile {
    display: none !important;
  }

  .show-mobile {
    display: flex !important;
  }

  .show-mobile-block {
    display: block !important;
  }
}
